import React from "react";
import { Chart } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import { KpiChartOptions } from "./ChartOptions";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const doughnutCenterLabel = {
  id: "doughnutCenterLabel",
  beforeDraw(chart, args, pluginOptions){
    const {ctx, data} = chart;
    ctx.save();
    const xCoor = chart.getDatasetMeta(0).data[0].x
    const yCoor = chart.getDatasetMeta(0).data[0].y
    const fontSize = ((chart.height / 1000) + 0.2).toFixed(2)
    ctx.font = "bold " + fontSize + "em 'Frankfurter Std Regular'";
    ctx.fillStyle = 'rgb(0,0,0)';
    ctx.textAlign='center';
    ctx.baseline = 'middle';
    ctx.lineCap = 2;
    ctx.lineWidth = 'round';
    ctx.fillText('PRODUCTS DONATED', xCoor, yCoor - ((fontSize * 30) - 10));
    ctx.fillText('AS % OF TOTAL', xCoor, yCoor + ((fontSize * 30) - 10));
  }
}

const chartDataLabels = {
  ChartDataLabels,
  beforeDraw(chart, args, pluginOptions){
    const {ctx, data} = chart
    ctx.save()
    const fontSize = (((chart.height / 1000) + 0.2) * 18).toFixed(2)
    data.datasets[0].datalabels.font.size = fontSize
  }
}

Chart.register(ChartDataLabels);

const KpiChart = React.forwardRef(({ data }, ref) => {
  return <Doughnut options={KpiChartOptions}
  data={data}
  plugins={[chartDataLabels, doughnutCenterLabel]}
  ref={ref}/>;
});

export default KpiChart;
