import React, { useEffect } from 'react'
import { get, map, includes, split, filter } from 'lodash'
import { Field } from 'formik'
import { Select } from 'formik-material-ui'
import { Select as MaterialSelect } from '@material-ui/core'
import {
	MenuItem,
	Typography,
	Checkbox,
	FormControl,
	InputLabel
} from '@material-ui/core'
import { unpackFormikPropsForField } from 'actions/form'


export const FormikDropdownField = ( { label, name, options, margin, fullWidth, size, fontSize, width, disabled = false, helperText, onChange, validate_on_select, placeholder, formik_props, className, ...props } ) => {

	const { errors, touched } = unpackFormikPropsForField( { formik_props, name } )

	const value = get( formik_props.values, name )

	useEffect( () => {
		formik_props.setFieldValue( name, value )
	}, [ value ] )

	const handleChange = ( event ) => {
		const newValue = event.target.value
		formik_props.setFieldValue( name, newValue )
		formik_props.setFieldTouched( name, true )
		if ( onChange ) {
			onChange( newValue ) // This correctly forwards the change
		}
	}

	const marginTop = !label && margin == 'dense' ? 0 : 0
	const marginBottom = !label && margin == 'dense' ? 0 : 8
	const inputLabelMarginTop = margin == 'dense' ? 3 : -7

	return (
		<>
			<FormControl fullWidth size={ size } className={ className }>
				<InputLabel style={ { marginLeft: 15, marginTop: inputLabelMarginTop } }>
					{ label || placeholder }
				</InputLabel>
				<MaterialSelect
					label={ label || placeholder }
					id={ name }
					value={ value }
					onChange={ handleChange }
					error={ errors }
					variant="outlined"
					fullWidth={ fullWidth }
					margin={ margin }
					placeholder={ placeholder }
					disabled={ disabled }
					style={ { marginTop: marginTop, marginBottom: marginBottom, paddingBottom: 0, fontSize: fontSize, width: width } }
				>
					{ map( options, ( option ) => (
						<MenuItem key={ option.value } value={ option.value }>
							{ option.label }
						</MenuItem>
					) ) }
				</MaterialSelect>
				{ errors && <Typography variant="caption" style={ { color: 'red' } }>{ errors }</Typography> }
			</FormControl>
			{ helperText && <Typography variant="caption">{ helperText }</Typography> }
		</>
	)
}

export const FormikMultiSelectDropdownField = ( { label, name, options, on_change, placeholder, formik_props, value, style, ...props } ) => {
	const { touched } = formik_props
	const selected_options = get( formik_props.values, name, [] )

	const renderValue = ( selected_values ) => {
		const selected_options = filter( options, ( option ) => includes( selected_values, option.value ) )
		const selected_labels = map( selected_options, ( option ) => option.label )
		return selected_labels.join( ', ' )
	}

	useEffect( () => {
		if ( !get( formik_props.values, name ) ) {
			formik_props.setFieldValue( name, [] )
		}
	}, [ get( formik_props.values, name ) ] )

	if ( !get( formik_props.values, name ) ) {
		return "Loading..."
	}

	return (
		<FormControl variant="outlined" fullWidth style={ style }>
			<InputLabel id={ `${ label }` }>{ label || placeholder }</InputLabel>
			<Field
				component={ Select }
				name={ name }
				label={ label || placeholder }
				placeholder={ label || placeholder }
				id={ name }
				renderValue={ renderValue }
				multiple
				helperText={ touched ? get( formik_props, [ "errors", name ] ) : "" }
				error={ touched && Boolean( get( formik_props, [ "errors", name ] ) ) }
				margin="normal"
				variant="outlined"
				fullWidth
				{ ...props }
			>
				{ map( options, ( option ) => (
					<MenuItem key={ option.value } value={ option.value }>
						<Checkbox checked={ includes( selected_options, option.value ) } style={ {
							color: "#001E3C",
						} } />
						{ option.label }
					</MenuItem>
				) ) }
			</Field>
		</FormControl>
	)
}

export const FormikColumnSelectDropdownField = ( { label, name, options, on_change, placeholder, formik_props, style, ...props } ) => {
	const { touched } = formik_props
	const selected_options = get( formik_props.values, name, [] )

	const renderValue = ( selected_values ) => {
		const selected_options = filter( options, ( option ) => includes( selected_values, option.field ) )
		const selected_labels = map( selected_options, ( option ) => option.title )
		return selected_labels.join( ', ' )
	}

	useEffect( () => {
		if ( !get( formik_props.values, name ) ) {
			formik_props.setFieldValue( name, [] )
		}
	}, [ get( formik_props.values, name ) ] )

	if ( !get( formik_props.values, name ) ) {
		return "Loading..."
	}

	return (
		<FormControl variant="outlined" fullWidth style={ style }>
			<InputLabel id={ `${ label }` }>{ label || placeholder }</InputLabel>
			<Field
				component={ Select }
				name={ name }
				label={ label || placeholder }
				placeholder={ label || placeholder }
				id={ name }
				renderValue={ renderValue }
				multiple
				helperText={ touched ? get( formik_props, [ "errors", name ] ) : "" }
				error={ touched && Boolean( get( formik_props, [ "errors", name ] ) ) }
				margin="normal"
				variant="outlined"
				fullWidth
				{ ...props }
			>
				{ map( options, ( option ) => (
					<MenuItem key={ option.value } value={ option.value }>
						<Checkbox checked={ includes( selected_options, option.field ) } />
						{ option.title }
					</MenuItem>
				) ) }
			</Field>
		</FormControl>
	)
}
