import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, Link } from 'react-router-dom'
import { get } from 'lodash'
import Timestamp from 'components/Timestamp'
import CurrencyValue from 'components/CurrencyValue'
import AdminCommonListLayout from 'admin/components/layout/AdminCommonListLayout'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { adminMoneyDonationList } from './actions/admin_money_donation'
import { Separator } from 'components/layout/Separator'
import { countryList } from 'actions/country'
import { provinceList } from 'actions/province'
import { statusList } from 'actions/status'
import { Hidden, Button } from '@material-ui/core'
import { ReportDownloadLink } from 'components/layout/ReportDownloadLink'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Chip, Tooltip } from '@material-ui/core'
import { ShortId } from 'components/ShortId'
import { payment_status_colours }  from 'components/payments/PaymentConfig'

export default function AdminMoneyDonations(props) {

    const dispatch = useDispatch()
    const history = useHistory()
    const is_loading = useSelector(() => adminMoneyDonationList.isLoading())
    const money_donations = useSelector(() => adminMoneyDonationList.getVisibleObjects())

    useEffect(() => {
        dispatch(adminMoneyDonationList.fetchListIfNeeded())
    }, [])

    const onUpdateListOrdering = (field) => {
      dispatch(adminMoneyDonationList.updateListOrdering(field))
    }

    const onAddMoneyDonation = () => {
        history.push('/admin/money_donation')
    }

    const onEditMoneyDonation = (money_donation_id) => {
        history.push('/admin/money_donation')
        history.push({
            pathname: '/admin/money_donation/'+money_donation_id
        })
    }

    const renderDonatedBy = (item) => {
      if(get(item, ["donator", "first_name"])) {
        return (
          <span>{get(item, ["donator", "first_name"])} {get(item, ["donator", "last_name"])}</span>
        )
      }
      else  if(get(item, "gateway_donator")) {
        return (
          <span>{get(item, "gateway_donator")}</span>
        )
      }
    }

    const renderDateSetStatus = (date) => {
      if(date) {
        return(
          <Tooltip arrow title={<Timestamp value={date} />}>
            <FiberManualRecordIcon style={{color: 'green'}} />
          </Tooltip>
        )
      }
      else { return(<FiberManualRecordIcon style={{color: 'red'}} />) }
    }

    const renderPaymentStatus = (status) => {
       var color = payment_status_colours[status]
       return (
         <Chip label={status} style={{background: color}} />
       )
    }

    const columns = [


      { field: 'unique_payment_ref',
          title: 'Ref',
          render: (item) => <ShortId value={item.unique_payment_ref} />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'unique_payment_ref' : '-unique_payment_ref'),
        },
        { field: 'donator',
          title: 'Donated by',
          render: (item) => renderDonatedBy(item),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'donator__email' : '-donator__email')
        },
        { field: 'thankyou_email_sent_at',
          title: 'Thank you',
          render: (item) => renderDateSetStatus(item.thankyou_email_sent_at),
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'thankyou_email_sent_at' : '-thankyou_email_sent_at')
        },
        { field: 'section18a_email_sent_at',
          title: 'Section18A',
          render: (item) => renderDateSetStatus(item.section18a_email_sent_at),
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'section18a_email_sent_at' : '-section18a_email_sent_at')
        },
        { field: 'source_name',
          title: 'Source',
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'source__name' : '-source__name')
        },
        { field: 'gateway',
          title: 'Gateway',
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'source' : '-source'),
        },
        { field: 'amount_rands',
          title: 'Amount',
          render: (item) => <CurrencyValue value={item.amount_rands} />,
          sort:(direction) => onUpdateListOrdering(direction === 'asc' ? 'amount_rands' : '-amount_rands')
        },
        { field: 'paid_at',
          title: 'Donation Date',
          render: (item) => <Timestamp value={item.paid_at} format='date' />,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'paid_at' : '-paid_at'),
        },
        { field: 'comments',
          title: 'Comments',
          render: (item) => <span>{ get(item, 'comments') }</span>,
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'comments' : '-comments'),
        },
        { field: 'status',
          title: 'Status',
          render: (item) => renderPaymentStatus(item.status),
          sort: (direction) => onUpdateListOrdering(direction === 'asc' ? 'status' : '-status')
        },
    ]

    return (
        <AdminCommonListLayout
            active_key="money_donations"
            breadcrumbs={[
                {name: 'home'},
                {name: 'money_donations', label: "Money donations", url: '/admin.money_donations'}
            ]}
            is_loading={is_loading}
            add_label="New Money Donation"
            columns={columns}
            enableAnyFieldFilter={true}
            item_list={adminMoneyDonationList}
            onAddRow={onAddMoneyDonation}
            onEditRow={onEditMoneyDonation}
            title={"Money Donations"}
        />
    )
}
