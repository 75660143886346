/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

const MetricPill = React.forwardRef(({ data, fontSize }, ref) => {
  return (
    <div css={ pill_container } ref={ ref }>
      <div css={ pill_label_container }>
        <p css={ p }>{ data.label }</p>
      </div>
      <div css={[ pill_value, { fontSize: fontSize } ]}>
        <p css={ p }>{ data.value }</p>
      </div>
    </div>
  );
});

export default MetricPill;


const pill_container = css`
  border: 2px solid black;
  border-radius: 2rem;
  width: 28rem;
  height: 3.3rem;
  margin: 0.5rem;
  padding-inline: 0.2rem;
  padding-block: 0.5rem;  
  display: flex;
  align-items: center;
  flex-direction: row;
`

const metric_container = css`
  display: flex;
  justify-content: center;
  width: 30%;
  height: fit-content;
  padding: 1rem;
`

const p = css`
  color: black;
  font-weight: 900;
  margin-block: 0.2rem;
  font-family: "Frankfurter Std Regular";
`

const pill_label_container = css`
  font-size: large;
  border: 1px solid rgb(251, 223, 6);
  border-radius: 2rem;
  background-color: rgb(251, 223, 6);
  padding-inline: 0.5rem;
  width: 13rem;
  height: 2.7rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`

const pill_value = css`
  display: flex;
  font-size: 2rem;
  padding-inline: 1rem;
  width: 12rem;
  justify-content: center;
`
