/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

const DoughnutChart = React.forwardRef(({ data, fontSize }, ref) => {
  return (
    <div ref={ ref }>
      <div css={ outer_ring }>
        <div css={ inner_ring }>
          <div css={ inner_most_ring }>
            <span css={[ amount_style, { fontSize: fontSize } ]}>
              { data.amount }
            </span>
            <span css={ description }>{ data.description }</span>
          </div>
        </div>
      </div>
      <div css={ vertical_dashed_line }></div>
      <div css={ doughnut_summary }>
        <span>
          <span css={ summary_text }>{ data.trend }</span>
          <span
            css={ [summary_percentage, data.trend === "Up" ? trend_up : trend_down] }
          >
            { data.percentage }
            <span css={ vertical_align_super }>%</span>
          </span>
        </span>
        <span css={ summary_text }>from last period</span>
      </div>
    </div>
  );
});

export default DoughnutChart;


const outer_ring = css`
  width: 13rem;
  height: 13rem;
  background-color: rgb(251,223,6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
  width: 11rem;
  height: 11rem;
  }
`

const inner_ring = css`
  width: 10rem;
  height: 10rem;
  background-color: rgb(193,164,22);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
  width: 8rem;
  height: 8rem;
  }
`

const inner_most_ring = css`
  width: 8rem;
  height: 8rem;
  background-color: rgb(14, 14, 14);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;

  @media print {
  width: 6.5rem;
  height: 6.5rem;
  }  
`

const amount_style = css`
  font-size: 2rem;
  color: rgb(255, 255, 255);
  font-family: Frankfurter Std Regular;

  @media print {
  font-size: 1rem;
  }
`

const vertical_align_super = css`
  font-size: 1rem;
  vertical-align: super;

  @media print {
  font-size: 0.5rem;
  }
`

const description = css`
  font-size: 0.6rem;
  color: rgb(255, 255, 0);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 7rem;

  @media print {
  font-size: 0.5rem;
  width: 4rem;
  }
`

const vertical_dashed_line = css`
  height: 4.5rem;
  position: absolute;
  z-index: 100;
  border-left: 2px dashed rgb(0, 0, 0);
  margin-left: 7rem;
  margin-top: -3rem;

  @media print {
  border-left: 0.1rem dashed rgb(0, 0, 0);
  margin-left: 5.5rem;
  margin-top: -3.5rem;
  }
`

const doughnut_summary = css`
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(0, 0, 0);
  margin-top: 1rem;

  @media print {
  margin-top: 0.5rem;
  }
`

const summary_text = css`
  font-size: 0.4rem;
`
const summary_percentage = css`
  font-family: Frankfurter Std Regular;
  font-size: 1.3rem;

  @media print {
  font-size: 1rem;
  }  
`

const trend_up = css`
  color: rgb(214, 182, 85);
`
const trend_down = css`
  color: rgb(94, 77, 55);
`
