/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import BranchMetricChart from "./BranchMetricsChart";
import SummaryTable from "./SummaryTable";
import MetricPill from "./MetricPill";
import MealsDeliveredBarChart from "./MealsDeliveredBarChart";
import MealsPersonPeopleServeBarChart from "./MealsPersonPeopleServedBarChart";
import DoughnutChart from "./DoughnutChart";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import KpiChart from "./KpiChart";
import ReportBreakdown from "./ReportBreakdown";

Chart.register(CategoryScale);

const Report = React.forwardRef(({ props }, ref) => {
  const doughnutFontSize = getDoughnutFontSize(props.doughnutData)
  const pillFontSize = getPillFontSize(props.chips)
  return (
    <div ref={ ref } css={ report_page }>
      <div css={ report_container }>
        <div css={ body_height }>
          <div css={ kpi_container }>
            <ReportBreakdown data={ props.summaryTable } />
          </div>
          <div>
            <p css={ heading }>{ props.summaryTable.current_period }</p>
          </div>
          <div css={ doughnuts_pills }>
            { props.doughnutData.map((data, index) => (
              <div css={ doughnuts }>
                <DoughnutChart key={ index } data={ data } fontSize={ doughnutFontSize } />
              </div>
              ))}
          </div>
          <div css={ heading_container }>
            <div>
              <p css={ heading }>Totals</p>
            </div>
            <div css={ chart_container }>
              { props.chips.map((metric, index) => (
                <div css={ metric_container }>
                  <MetricPill key={ index } data={ metric } fontSize={ pillFontSize } />
                </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div css={ report_container }>
        <div css={ body_height }>
          <div css={ [kpi_container, top] }>
            <p css={ [kpi_title, p] }>SUMMARY</p>
          </div>
          <div css={ summary_table_section }>
            <div css={ table_header_label }>
              <p css={ p }>Metrics</p>
              <SummaryTable tableData={ props.metricTable } />
            </div>
            <div css={ table_header_label }>
              <p css={ p }>Provinces</p>
              <SummaryTable tableData={ props.provinceTable } />
            </div>
          </div>
          <div css={ charts }>
            <div css={ chart }>
              <MealsDeliveredBarChart data={ props.mealsDeliveredData } />
            </div>
            <div css={ chart }>
              <MealsPersonPeopleServeBarChart
                data={ props.mealsPersonPeopleServeData }
              />
            </div>
            <div css={ chart }>
              <BranchMetricChart bars={ props.branchMetrics } />;
            </div>
          </div>
        </div>
      </div>
      <div css={ report_container }>
        <div css={ body_height }>
          <div css={ kpi_flexi }>
            <div css={ [kpi_container, side] }>
              <p css={ [kpi_title, p] }>KPI's</p>
            </div>

            <div css={ content_flexi }>
              <KpiChart data={props.kpiChart} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Report;

const getDoughnutFontSize = (list) => {
  let maxLength = 0
  list.map((data, index) => {
    if (data.amount.length > maxLength) {
      maxLength = data.amount.length
    }
  })
  return calcDoughnutFontSize(maxLength)
}

const calcDoughnutFontSize = (textLength) => {
  if (textLength < 7) {
    return '2rem'
  } else if (textLength > 6 && textLength < 8) {
    return '1.5rem'
  } else if (textLength > 7 && textLength < 10) {
    return '1.25rem'
  } else if (textLength > 9 && textLength < 13) {
    return '1rem'
  } else {
    return '0.5rem'
  }
}

const getPillFontSize = (list) => {
  let maxLength = 0
  list.map((data, index) => {
    if (data.value.length > maxLength) {
      maxLength = data.value.length
    }
  })
  return calcPillFontSize(maxLength)
}

const calcPillFontSize = (textLength) => {
  if (textLength < 10) {
    return '2rem'
  } else if (textLength > 9 && textLength < 13) {
    return '1.5rem'
  } else if (textLength > 12 && textLength < 16) {
    return '1.25rem'
  } else if (textLength > 15 && textLength < 20) {
    return '1rem'
  } else {
    return '0.5rem'
  }
}

const chart_container = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const content_size = css`
  height: 50%;
  width: 50%;
`

const kpi_container = css`
  padding-left: 4rem;
  background: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`

const top = css`
  width: inherit;
  height: 10%;
`

const side = css`
  width: 10%;
`

const content_flexi = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 90%;
  margin-block: 2rem;
`

const kpi_flexi = css`
  display: flex;
  flex-direction: row;
  height: 100%;
`

const kpi_title = css`
  color: #ffff00 !important;
  font-weight: 900;
  font-size: xxx-large;
`

const report_container = css`
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

const body_height = css`
  height: 100%;
  width: 100%;
`

const chartjsbar = css`
  width: 100%;
  max-width: 30rem;
`

const summary_table_section = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 42%;
`

const charts = css`
  display: flex;
  flex-direction: row;
  width: 98%;
  height: 40%;
  margin: 4rem 1rem 1rem 1rem;
  justify-content: space-between;
`

const chart = css`
  display: flex;
  width: 29rem;
  padding: 0.7rem;
`

const pill_container = css`
  border: 2px solid black;
  border-radius: 2rem;
  width: 28rem;
  height: 3.3rem;
  margin: 0.5rem;
  padding-inline: 0.2rem;
  padding-block: 0.5rem;  
  display: flex;
  align-items: center;
  flex-direction: row;

  @media print {
    width: 23rem;
  }
`

const metric_container = css`
  display: flex;
  justify-content: center;
  width: 30%;
  height: fit-content;
  padding: 1rem;
`

const p = css`
  color: black;
  font-weight: 900;
  margin-block: 0.2rem;
  font-family: "Frankfurter Std Regular";
`

const pill_label_container = css`
  font-size: large;
  border: 1px solid rgb(251, 223, 6);
  border-radius: 2rem;
  background-color: rgb(251, 223, 6);
  padding-inline: 0.5rem;
  width: 13rem;
  height: 2.7rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`

const pill_value = css `
  display: flex;
  font-size: 2rem;
  padding-inline: 1rem;
  width: 12rem;
  justify-content: center;
`

const print_button = css`
  width: 8rem;
  height: 2rem;
  font-size: 1rem;
  color: snow;
  margin: 1rem 0rem 1rem 0rem;
  border-radius: 0.2rem;
  border: none;
  background-color: rgb(67, 106, 196);
  cursor: pointer;
  outline: none;
`
const doughnuts_pills = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media print {
    margin-top: 2rem;
    margin: 0.5rem;
  }
`

const doughnuts = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  @media print {
    margin: 0.5rem;
  }
`

const table_header_label = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
  padding-inline: 2rem;
  padding-block: 0;
  align-items: flex-start;
`

const report_page = css`
  text-align: center;
  width: 100%;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;

  @media print {
    @page {
      size: landscape !important;
    }
  }
`

const heading = css`
  color: black;
  font-family: "Frankfurter Std Regular";
  font-size: 2rem;
`

const heading_container = css`
  border-block: 1px solid black;
  margin-top: 1.5rem;
`
