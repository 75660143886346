import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { forEach, head, get, map, keys, split, size } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {FormControl, FormControlLabel, Grid} from '@material-ui/core'
import { Separator } from 'components/layout/Separator'
import { Formik, Form, Field } from 'formik'
import { FormikDropdownField } from 'components/form/Dropdown'
import { FormikInputField } from 'components/form/InputField'
import { FormikCheckboxField } from 'components/form/CheckboxField'
import { FormikTextareaField } from 'components/form/TextareaField'
import * as Yup from 'yup'
import {showSuccess, showError} from 'actions/Error'
import { handleSubmitResult } from 'actions/form'
import Timestamp from 'components/Timestamp'
import { Card } from 'components/layout/Card'
import { payment_status_options, payment_gateway_options } from 'components/payments/PaymentConfig'

export const validationSchema = Yup.object().shape({
})

export const AdminMoneyDonationForm = ({formik_props}) => {

    const dispatch = useDispatch()

    return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card content={
                <>

                      <FormikInputField
                        name="amount_rands"
                        id="amountRands"
                        label="Amount"
                        formik_props={formik_props}
                      />
                      <br />
                      <FormikInputField
                        name="donator.first_name"
                        id="firstName"
                        label="First Name"
                        formik_props={formik_props} />
                      <br />
                      <FormikInputField
                        name="donator.last_name"
                        id="lastName"
                        label="Last Name"
                        formik_props={formik_props}
                      />
                      <br />
                      <FormikInputField
                        name="donator.email"
                        id="email"
                        type="email"
                        label="Email"
                        formik_props={formik_props}
                      />
                      <br />
                      <FormikInputField
                        name="donator.cell_number"
                        id="cellNumber" type="tel"
                        label="Mobile number"
                        formik_props={formik_props}
                      />
                      <br />
                      <div>
                        <FormControlLabel
                          control={
                            <FormikCheckboxField
                              name="newsletter_enabled"
                              color="primary"
                              formik_props={formik_props} />
                          }
                          label="Sign up for the SA Harvest Newsletter"
                          style={{marginLeft: 0}} />
                        <br />
                        <FormControlLabel
                          control={
                            <FormikCheckboxField
                              name="anonymous_donation"
                              color="primary"
                              formik_props={formik_props} />
                          }
                          label="Anonymous donation?"
                          style={{marginLeft: 0}} />
                        <br />
                          <FormControlLabel
                            control={
                              <FormikCheckboxField
                                name="tax_certificate_requested"
                                color="primary"
                                formik_props={formik_props} />
                            }
                            label="Receive a Section18A Tax Exemption Certificate?"
                            style={{marginLeft: 0}} />
                          <br />
                      </div>
              </>

              }
            />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Card title={"Payment Status"}
                    content={
                      <FormikDropdownField name="status"
                           formik_props={formik_props}
                           options={payment_status_options}
                           empty_selection_label="No code"
                           placeholder="Payment Status"
                      />
                    }
              />
            <Card title={"Payment Gateway"}
                    content={
                      <FormikDropdownField name="gateway"
                           formik_props={formik_props}
                           options={payment_gateway_options}
                           empty_selection_label="No code"
                           placeholder="Payment Gateway"
                      />
                    }
              />
            <Card title={"Comments"}
                    content={
                      <FormikTextareaField
                        name="comments"
                        id="comments"
                        label="Comments"
                        formik_props={ formik_props }
                      />
                    }
              />
            </Grid>
        </Grid>
    )
}
